
import Paper from '@mui/material/Paper';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';

import Bible  from '../../../Service/Bible';
import Verse  from '../Chapter/Verse'; 

import PreviousAndNextButtons from './PreviousAndNextButtons'
import Grid from '@mui/material/Grid'; 
import { useLocation } from 'react-router-dom';
import './Chapter.css'; 

const Chapter =(props) => {  
    const sectionRefs = useRef([]);
    const path = useLocation();
    const paramteters = path.pathname.replace('/', '').split('/');
    const getChapter = (bookList, chapter, index) => {
        let bookAndTotalChapters =   bookList.find(book => book.type === 'book'); 
        
        if(Number(chapter) + index > 0 && Number(chapter) + index < 46)
        {
            return  `${paramteters[1]}/${Number(paramteters[2]) + index}/1`
        }
        else if(Number(chapter) + index === 0)
        {
             
            return `${paramteters[1]}/${paramteters[2]}/1`
        }
        else 
        {
            return  `${paramteters[1]}/${paramteters[2]}/1`
        }
    
    }

    const scrollToSection = (index) => {
        if (sectionRefs.current[index]  ) {
            setTimeout(() => {   
          sectionRefs.current[index].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
          
      }, 1000);

          setTimeout(() => {
      // Scroll by an additional 10 pixels
      window.scrollBy({
        top: -10,
        behavior: 'smooth'
      });
    }, 500)
        }
      };

    let bookList = props.lookup.filter(l=> l.type ==="book");
    const [pageContent, setPageContent] = useState({book: paramteters[1], 
            chapter: paramteters[1],  
            verse: paramteters[2],
            text: [], 
            previousChapter: getChapter(
                props.lookup, paramteters[2],  - 1), 
            nextChapter: getChapter(props.lookup, paramteters[2], 1) }); 

    const isActiveVerseClassName = (verseNumber) => {
        return parseInt(pageContent.verse) === parseInt(verseNumber) && parseInt(pageContent.verse) > 1 ? 'active-verse' : 'verse';
            
    }

    useLayoutEffect(() => {
        if(paramteters[3] > 5)
            {
                scrollToSection(paramteters[3])
            }
            else 
            {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth' // Optional for smooth scrolling
                  });
                  
            }

    }, [pageContent.verse])

    useEffect(() => {
                const fetchData = async () => {
                  try {
                    const response = await Bible.getChapterText(paramteters[1], paramteters[2]);
                    setPageContent({...pageContent, 
                        text: response.data, 
                        chapter: paramteters[2],  
                        verse: paramteters[3],
                        previousChapter: getChapter(
                            bookList, paramteters[2],  - 1), 
                        nextChapter: getChapter(bookList, paramteters[3], 1) 
                        });
                        
                  } catch (error) {
                    console.error('Error fetching data:', error);
                  }
                };
            
                fetchData();
                
              }, [path.pathname])
        
    

    return (
        <div class="result">
                <PreviousAndNextButtons previousChapter={pageContent.previousChapter} nextChapter={pageContent.nextChapter} />
            <div class="chapter">
                <Paper elevation={8} className='page-paper' > 
                <div id="chapter-header">
                    <h3>{pageContent.book.normalize("NFD")} </h3>
                    <h2> Capítulo {pageContent.chapter} </h2>    
                </div>
                <div>
                <div>
                <Grid container spacing={1}>
                
                   {    
                        pageContent.text.map( verse => {
                        return (<>
                        <Grid className='chapter-verse-number' 
                            item xs={1} md={1}
                            ref={(el) => (sectionRefs.current[verse.number] = el)} 
                            >
                            {verse.number} 
                        </Grid><Grid item className='chapter-verse-text' xs={11} md={11}>
                            <Verse verseNumber={verse.number} text={verse.text} />
                            </Grid></>
                        )  
                        })
                    }
                </Grid> 
                </div> 
                </div>
                </Paper> 
            </div>
            
            <PreviousAndNextButtons previousChapter={pageContent.previousChapter} nextChapter={pageContent.nextChapter} />
        </div>
    )

}

export default Chapter; 