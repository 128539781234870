import { useState} from 'react';
import Button from '@mui/material/Button'; 
import EditableText from '../../Common/EditableText';
import './Verse.css'; 

const user = {isAdmin: true}



const Verse =(props) => {

    const [verseText, setVerseText] = useState(props.text);
    const user = {isAdmin: true}
    const handleChange = (event) => {
    setVerseText(event.target.value);
  };
    return (<>
     {user.isAdmin &&
        <div><EditableText
                id={props.verseId}
                text={props.text}  onChange={handleChange} />
                
                </div>
      }

    </>)
}


export default Verse; 